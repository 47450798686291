<template>
	<div class="product-container">
		<div class="scroll-container">
			<div class="bg-wrapper">
				<div class="content-wrapper">
					<!-- 注册 -->
					<div class="item-register-wrap">
						<h1 class="item-title">扫描二维码注册</h1>
						<div class="item-login-content">
							<div class="item-erCode"><img v-if="imgData && imgData.qr_url" :src="imgData.qr_url" alt="公众号注册二维码" /></div>

							<div class="item-tips-wx">微信扫一扫/注册账号</div>
							<div class="item-line"></div>

							<p class="item-tips">
								已有账号？
								<span class="item-regin" @click="goLogin">去登录</span>
							</p>

							<!-- <el-form ref="form" :model="form" :rules="rules" label-width="0px">
                <div class="item-left-wrap">
                  <el-form-item prop="name">
                    <el-input placeholder="姓名" v-model="form.name"></el-input>
                  </el-form-item>
                  <el-form-item prop="hospitalId">
                    <el-select v-model="form.hospitalId" placeholder="医院" style="width:100%">
                      <el-option v-for="(item,index,key) in hospitalList" :key="key" :label="item.name" :value="item.id">
                      </el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item prop="departmentId">
                    <el-select v-model="form.departmentId" placeholder="科室" style="width:100%">
                      <el-option v-for="(item,index,key) in departmentList" :key="key" :label="item.name" :value="item.id">
                      </el-option>
                    </el-select>
                  </el-form-item>

                  <el-form-item prop="titleId">
                    <el-select v-model="form.titleId" placeholder="科室" style="width:100%">
                      <el-option v-for="(item,index,key) in titleList" :key="key" :label="item.name" :value="item.id">
                      </el-option>
                    </el-select>
                  </el-form-item>
                </div>

                <div class="item-right-wrap">
                  <el-form-item prop="cityId">
                    <el-cascader
                      style="width:100%"
                      v-model="form.cityId"
                      :options="options"
                      :props="city_props"

                      @change="handleChange">
                        <template slot-scope="{node, data }">
                          <span>{{ data.name }}</span>
                          <span>{{ data.value }}</span>
                        </template>
                      </el-cascader>
                  </el-form-item>

                  <el-form-item prop="phone">
                    <input v-model="form.phone" class="phone" type="text" placeholder="手机号">
                  </el-form-item>

                  <el-form-item prop="code">
                    <div class="item-send-code">
                      <input v-model="form.code" class="code" type="text" placeholder="验证码">
                      <button :disabled="disabled" :class="['sendCode',{'active': disabled}]" @click="sendCode">{{ codeBtnText }}</button>
                    </div>
                  </el-form-item>
                <button :loading="loading" class="item-login" @click="submitForm">注册</button>
                <p class="item-tips">已有账号？<span class="item-regin" @click="goLogin">去登录</span></p>
                </div>
              </el-form> -->
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import indexApi from '@/api/index';
import cityJson from '@/config/city';
let callBackUrl;
export default {
	data() {
		return {
			imgData: '',
			type: 'register',
			form: {
				phone: '',
				code: '',
				name: '',
				cityId: '',
				hospitalId: '',
				departmentId: '',
				titleId: ''
			},

			disabled: false,
			codeBtnText: '发送验证码',
			loading: false,

			options: cityJson,
			city_props: {
				value: 'code',
				label: 'name',
				children: 'cities'
			},

			hospitalList: [], // 医院
			departmentList: [], // 科室
			titleList: [], //职称

			rules: {
				name: [
					{ required: true, message: '请输入活动名称', trigger: 'blur' }
					// { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
				],
				code: [
					{ required: true, message: '请输入验证码', trigger: 'blur' }
					// { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
				],
				phone: [
					{ required: true, message: '请输入手机号', trigger: 'blur' }
					// { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
				],
				hospitalId: [{ required: true, message: '请选择医院', trigger: 'change' }],
				cityId: [{ required: true, message: '请选择城市', trigger: 'change' }],
				departmentId: [{ required: true, message: '请选择科室', trigger: 'change' }],
				titleId: [{ required: true, message: '请选择职称', trigger: 'change' }]
			}
		};
	},
	created() {
		let that = this;
    callBackUrl = that.$route.query.callBackUrl;
		//this.getSelectData();
	},
	mounted() {
    let that = this;
		let imgData = JSON.parse(that.$stroage.getLocalItem('public_qr_url'));
		if (imgData && imgData.qr_url) {
			that.imgData = imgData;
		} else {
			that.$point.getQrUrl({ sourceId: '' }, res => {
				that.imgData = res;
				that.$stroage.setLocalItem('public_qr_url', res);
			});
		}
	},
	methods: {
		getSelectData() {
			let params = {};
			indexApi.getSelectData(params).then(
				res => {
					this.hospitalList = (res && res.data && res.data.hospitalList) || []; // 医院
					this.departmentList = (res && res.data && res.data.departmentList) || []; // 科室
					this.titleList = (res && res.data && res.data.titleList) || []; //职称
				},
				err => {
					console.log(err);
				}
			);
		},
		handleChange(data) {
			this.cityId = data && data.length > 1 && data[1];
		},

		// 提交信息
		submitForm() {
			this.$refs.form.validate(valid => {
				if (valid) {
					this.register();
				} else {
					// console.log('error submit!!');
					return false;
				}
			});
		},

		// 发送验证码
		sendSmsCode() {
			let params = {
				phone: this.form.phone
			};
			indexApi.sendSmsCode(params).then(
				res => {
					if (res && res.code === 1000) {
						this.$message.success('发送成功');
					}
				},
				err => {
					this.$message.success((err && err.message) || '发送失败，请联系管理员！');
				}
			);
		},

		// 注册接口
		register() {
			let params = {};
			Object.assign(params, this.form);
			this.loading = true;
			indexApi.register(params).then(
				res => {
					if (res && res.code == 1000) {
						this.$message.success('注册成功');
						let token = res && res.data && res.data.token;
						this.$stroage.setLocalItem('token', token);
						this.$router.push({ name: 'registerNext' });
						this.loading = false;
					}
				},
				err => {
					this.loading = false;
					this.$message.success((err && err.message) || '注册失败，请联系管理员！');
				}
			);
		},

		// 去登陆
		goLogin() {
			// this.$router.push({ name: 'login',query:{callBackUrl}});
      const {href} = this.$router.resolve({name: "login"});
      let loginPath = callBackUrl?`${href}?callBackUrl=${callBackUrl}`:href;
      console.log("loginPath",loginPath)
      location.replace(loginPath);
    },

		// 发送验证码事件
		sendCode() {
			// console.log(this.sendCodeHandle(this.sendCodeHandle))
			this.sendCodeHandle();
		},

		// 发送验证码逻辑
		sendCodeHandle() {
			let secondCount = 60,
				timer = null;
			// this.sendSmsCode();
			timer = setInterval(() => {
				if (secondCount > 0) {
					this.disabled = true;
					this.codeBtnText = secondCount + 's后再发送';
					secondCount--;
				} else {
					clearInterval(timer);
					this.codeBtnText = '发送验证码';
					secondCount = 60;
					this.disabled = false;
				}
			}, 1000);
		}
	}
};
</script>

<style scoped lang="scss">
.product-container {
	background-image: url('~./images/bg.png');
	background-size: cover;
	line-height: 1;
	position: relative;

	.bg-wrapper {
		display: flex;
		align-items: flex-start;
		background: url('~./images/bg-login.png') center center / 100% 100%;
		width: 10.6rem;
		height: 8.4rem;
		// margin: .6rem auto 0;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		// padding-left: .3rem;
		// padding-top: .4rem;
		// padding-bottom: .4rem;
	}

	.content-wrapper {
		width: 100%;
		.item-login-wrap,
		.item-register-wrap {
			width: 100%;
			.item-title {
				text-align: center;
				font-size: 0.4rem;
				font-family: Noto Sans CJK SC;
				font-weight: bold;
				color: #ffffff;
				line-height: 0.48rem;
				text-align: center;
				margin-left: -0.06rem;
				// margin-top: .81rem;
				padding-top: 1rem;
			}
			.item-erCode {
				width: 2.8rem;
				height: 2.8rem;
				padding: 0.1rem;
				margin: auto;
				img {
					width: 100%;
					height: 100%;
				}
			}

			.item-tips-wx {
				font-size: 0.16rem;
				font-family: Noto Sans CJK SC;
				font-weight: 400;
				color: #333333;
				text-align: center;
				line-height: 0.36rem;
				margin-top: 0.29rem;
				&::before {
					width: 0.36rem;
					height: 0.36rem;
					content: '';
					display: inline-block;
					vertical-align: middle;
					background: url('./images/wechat.png') no-repeat center / 100% 100%;
					margin-top: -0.02rem;
				}
				p {
					margin-top: 0.1rem;
				}
			}
			.item-line {
				height: 0.06rem;
				width: 6.86rem;
				background: url('./images/line.png') no-repeat center / 100% 100%;
				margin: 0.27rem auto 0;
			}
			.item-login-content {
				width: 4.2rem;
				margin: 1.2rem auto 0;
				.phone {
					width: 4rem;
					height: 0.52rem;
					background: #f5f5f7;
					padding: 0.05rem 0.2rem;
					border: 1px solid #dddddd;
					border-radius: 0.06rem;
					font-size: 0.16rem;
					font-family: Noto Sans CJK SC;
					font-weight: 400;
					color: #999999;
					display: block;
					outline: none;
				}
				.item-send-code {
					margin-top: 0.25rem;
					.code {
						width: 2.5rem;
						height: 0.52rem;
						background: #f5f5f7;
						padding: 0.05rem 0.2rem;
						border: 1px solid #dddddd;
						border-radius: 0.06rem;
						font-size: 0.16rem;
						font-family: Noto Sans CJK SC;
						font-weight: 400;
						color: #999999;
						outline: none;
						margin-right: 0.1rem;
						float: left;
					}
					.sendCode {
						width: 1.4rem;
						height: 0.52rem;
						background: #eb3c96;
						border-radius: 0.06rem;
						font-size: 0.16rem;
						font-family: Noto Sans CJK SC;
						font-weight: 400;
						color: #ffffff;
						line-height: 0.48rem;
						cursor: pointer;
						&.active {
							background: #999999;
						}
					}
				}

				.item-login {
					width: 4rem;
					height: 0.52rem;
					background: #503291;
					border-radius: 0.06rem;
					font-size: 0.18rem;
					font-family: Noto Sans CJK SC;
					font-weight: 500;
					color: #ffffff;
					line-height: 0.48rem;
					text-align: center;
					margin-top: 0.25rem;
					cursor: pointer;
				}

				.item-tips {
					text-align: center;
					font-size: 14px;
					font-family: Noto Sans CJK SC;
					font-weight: 400;
					color: #999999;
					line-height: 20px;
					margin: 0.21rem 0.3rem 0 0;
					span {
						cursor: pointer;
						color: #eb3c96;
					}
				}
			}
		}

		.item-register-wrap {
			.item-send-code,
			.item-login {
				margin-top: 0 !important;
			}
			.item-login-content {
				width: auto !important;
				// margin: 1.6rem 1rem 0;
			}
			.item-left-wrap {
				width: 4rem;
				float: left;
			}

			.item-right-wrap {
				width: 4rem;
				float: right;
			}
			.el-input {
				height: 0.52rem;
			}
			.el-input__inner {
				font-size: 0.16rem !important;
				background: #f5f5f7;
				font-family: Noto Sans CJK SC;
				font-weight: 400;
				color: #999999;
				height: 0.52rem;
			}
		}
	}
	::-webkit-input-placeholder {
		color: #999999;
	}
	:-moz-placeholder {
		/* Firefox 18- */
		color: #999999;
	}
	::-moz-placeholder {
		/* Firefox 19+ */
		color: #999999;
	}
	:-ms-input-placeholder {
		color: #999999;
	}
}
</style>
