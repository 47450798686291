export default [
    {
      "name":"北京市",
      "level":"1",
      "code":"1100",
      "cities":[
        {
          "name":"北京市",
          "level":"1",
          "code":"1100"
        }
      ]
    },
    {
      "name":"天津市",
      "level":"1",
      "code":"1200",
      "cities":[
        {
          "name":"天津市",
          "level":"1",
          "code":"1200"
        }
      ]
    },
    {
      "name":"河北省",
      "level":"1",
      "code":"1300",
      "cities":[
        {
          "name":"石家庄市",
          "level":"2",
          "code":"1301"
        },
        {
          "name":"唐山市",
          "level":"3",
          "code":"1302"
        },
        {
          "name":"秦皇岛市",
          "level":"3",
          "code":"1303"
        },
        {
          "name":"邯郸市",
          "level":"3",
          "code":"1304"
        },
        {
          "name":"邢台市",
          "level":"3",
          "code":"1305"
        },
        {
          "name":"保定市",
          "level":"3",
          "code":"1306"
        },
        {
          "name":"张家口市",
          "level":"3",
          "code":"1307"
        },
        {
          "name":"承德市",
          "level":"3",
          "code":"1308"
        },
        {
          "name":"沧州市",
          "level":"3",
          "code":"1309"
        },
        {
          "name":"廊坊市",
          "level":"3",
          "code":"1310"
        },
        {
          "name":"衡水市",
          "level":"3",
          "code":"1311"
        }
      ]
    },
    {
      "name":"山西省",
      "level":"1",
      "code":"1400",
      "cities":[
        {
          "name":"太原市",
          "level":"2",
          "code":"1401"
        },
        {
          "name":"大同市",
          "level":"3",
          "code":"1402"
        },
        {
          "name":"阳泉市",
          "level":"3",
          "code":"1403"
        },
        {
          "name":"长治市",
          "level":"3",
          "code":"1404"
        },
        {
          "name":"晋城市",
          "level":"3",
          "code":"1405"
        },
        {
          "name":"朔州市",
          "level":"3",
          "code":"1406"
        },
        {
          "name":"晋中市",
          "level":"3",
          "code":"1407"
        },
        {
          "name":"运城市",
          "level":"3",
          "code":"1408"
        },
        {
          "name":"忻州市",
          "level":"3",
          "code":"1409"
        },
        {
          "name":"临汾市",
          "level":"3",
          "code":"1410"
        },
        {
          "name":"吕梁市",
          "level":"3",
          "code":"1411"
        }
      ]
    },
    {
      "name":"内蒙古自治区",
      "level":"1",
      "code":"1500",
      "cities":[
        {
          "name":"呼和浩特市",
          "level":"2",
          "code":"1501"
        },
        {
          "name":"包头市",
          "level":"3",
          "code":"1502"
        },
        {
          "name":"乌海市",
          "level":"3",
          "code":"1503"
        },
        {
          "name":"赤峰市",
          "level":"3",
          "code":"1504"
        },
        {
          "name":"通辽市",
          "level":"3",
          "code":"1505"
        },
        {
          "name":"鄂尔多斯市",
          "level":"3",
          "code":"1506"
        },
        {
          "name":"呼伦贝尔市",
          "level":"3",
          "code":"1507"
        },
        {
          "name":"巴彦淖尔市",
          "level":"3",
          "code":"1508"
        },
        {
          "name":"乌兰察布市",
          "level":"3",
          "code":"1509"
        },
        {
          "name":"兴安盟",
          "level":"3",
          "code":"1522"
        },
        {
          "name":"锡林郭勒盟",
          "level":"3",
          "code":"1525"
        },
        {
          "name":"阿拉善盟",
          "level":"3",
          "code":"1529"
        }
      ]
    },
    {
      "name":"辽宁省",
      "level":"1",
      "code":"2100",
      "cities":[
        {
          "name":"沈阳市",
          "level":"2",
          "code":"2101"
        },
        {
          "name":"大连市",
          "level":"3",
          "code":"2102"
        },
        {
          "name":"鞍山市",
          "level":"3",
          "code":"2103"
        },
        {
          "name":"抚顺市",
          "level":"3",
          "code":"2104"
        },
        {
          "name":"本溪市",
          "level":"3",
          "code":"2105"
        },
        {
          "name":"丹东市",
          "level":"3",
          "code":"2106"
        },
        {
          "name":"锦州市",
          "level":"3",
          "code":"2107"
        },
        {
          "name":"营口市",
          "level":"3",
          "code":"2108"
        },
        {
          "name":"阜新市",
          "level":"3",
          "code":"2109"
        },
        {
          "name":"辽阳市",
          "level":"3",
          "code":"2110"
        },
        {
          "name":"盘锦市",
          "level":"3",
          "code":"2111"
        },
        {
          "name":"铁岭市",
          "level":"3",
          "code":"2112"
        },
        {
          "name":"朝阳市",
          "level":"3",
          "code":"2113"
        },
        {
          "name":"葫芦岛市",
          "level":"3",
          "code":"2114"
        }
      ]
    },
    {
      "name":"吉林省",
      "level":"1",
      "code":"2200",
      "cities":[
        {
          "name":"长春市",
          "level":"2",
          "code":"2201"
        },
        {
          "name":"吉林市",
          "level":"3",
          "code":"2202"
        },
        {
          "name":"四平市",
          "level":"3",
          "code":"2203"
        },
        {
          "name":"辽源市",
          "level":"3",
          "code":"2204"
        },
        {
          "name":"通化市",
          "level":"3",
          "code":"2205"
        },
        {
          "name":"白山市",
          "level":"3",
          "code":"2206"
        },
        {
          "name":"松原市",
          "level":"3",
          "code":"2207"
        },
        {
          "name":"白城市",
          "level":"3",
          "code":"2208"
        },
        {
          "name":"延边朝鲜族自治州",
          "level":"3",
          "code":"2224"
        }
      ]
    },
    {
      "name":"黑龙江省",
      "level":"1",
      "code":"2300",
      "cities":[
        {
          "name":"哈尔滨市",
          "level":"2",
          "code":"2301"
        },
        {
          "name":"齐齐哈尔市",
          "level":"3",
          "code":"2302"
        },
        {
          "name":"鸡西市",
          "level":"3",
          "code":"2303"
        },
        {
          "name":"鹤岗市",
          "level":"3",
          "code":"2304"
        },
        {
          "name":"双鸭山市",
          "level":"3",
          "code":"2305"
        },
        {
          "name":"大庆市",
          "level":"3",
          "code":"2306"
        },
        {
          "name":"伊春市",
          "level":"3",
          "code":"2307"
        },
        {
          "name":"佳木斯市",
          "level":"3",
          "code":"2308"
        },
        {
          "name":"七台河市",
          "level":"3",
          "code":"2309"
        },
        {
          "name":"牡丹江市",
          "level":"3",
          "code":"2310"
        },
        {
          "name":"黑河市",
          "level":"3",
          "code":"2311"
        },
        {
          "name":"绥化市",
          "level":"3",
          "code":"2312"
        },
        {
          "name":"大兴安岭地区",
          "level":"3",
          "code":"2327"
        }
      ]
    },
    {
      "name":"上海市",
      "level":"1",
      "code":"3100",
      "cities":[
        {
          "name":"上海市",
          "level":"1",
          "code":"3100"
        }
      ]
    },
    {
      "name":"江苏省",
      "level":"1",
      "code":"3200",
      "cities":[
        {
          "name":"南京市",
          "level":"2",
          "code":"3201"
        },
        {
          "name":"无锡市",
          "level":"3",
          "code":"3202"
        },
        {
          "name":"徐州市",
          "level":"3",
          "code":"3203"
        },
        {
          "name":"常州市",
          "level":"3",
          "code":"3204"
        },
        {
          "name":"苏州市",
          "level":"3",
          "code":"3205"
        },
        {
          "name":"南通市",
          "level":"3",
          "code":"3206"
        },
        {
          "name":"连云港市",
          "level":"3",
          "code":"3207"
        },
        {
          "name":"淮安市",
          "level":"3",
          "code":"3208"
        },
        {
          "name":"盐城市",
          "level":"3",
          "code":"3209"
        },
        {
          "name":"扬州市",
          "level":"3",
          "code":"3210"
        },
        {
          "name":"镇江市",
          "level":"3",
          "code":"3211"
        },
        {
          "name":"泰州市",
          "level":"3",
          "code":"3212"
        },
        {
          "name":"宿迁市",
          "level":"3",
          "code":"3213"
        }
      ]
    },
    {
      "name":"浙江省",
      "level":"1",
      "code":"3300",
      "cities":[
        {
          "name":"杭州市",
          "level":"2",
          "code":"3301"
        },
        {
          "name":"宁波市",
          "level":"3",
          "code":"3302"
        },
        {
          "name":"温州市",
          "level":"3",
          "code":"3303"
        },
        {
          "name":"嘉兴市",
          "level":"3",
          "code":"3304"
        },
        {
          "name":"湖州市",
          "level":"3",
          "code":"3305"
        },
        {
          "name":"绍兴市",
          "level":"3",
          "code":"3306"
        },
        {
          "name":"金华市",
          "level":"3",
          "code":"3307"
        },
        {
          "name":"衢州市",
          "level":"3",
          "code":"3308"
        },
        {
          "name":"舟山市",
          "level":"3",
          "code":"3309"
        },
        {
          "name":"台州市",
          "level":"3",
          "code":"3310"
        },
        {
          "name":"丽水市",
          "level":"3",
          "code":"3311"
        }
      ]
    },
    {
      "name":"安徽省",
      "level":"1",
      "code":"3400",
      "cities":[
        {
          "name":"合肥市",
          "level":"2",
          "code":"3401"
        },
        {
          "name":"芜湖市",
          "level":"3",
          "code":"3402"
        },
        {
          "name":"蚌埠市",
          "level":"3",
          "code":"3403"
        },
        {
          "name":"淮南市",
          "level":"3",
          "code":"3404"
        },
        {
          "name":"马鞍山市",
          "level":"3",
          "code":"3405"
        },
        {
          "name":"淮北市",
          "level":"3",
          "code":"3406"
        },
        {
          "name":"铜陵市",
          "level":"3",
          "code":"3407"
        },
        {
          "name":"安庆市",
          "level":"3",
          "code":"3408"
        },
        {
          "name":"黄山市",
          "level":"3",
          "code":"3410"
        },
        {
          "name":"滁州市",
          "level":"3",
          "code":"3411"
        },
        {
          "name":"阜阳市",
          "level":"3",
          "code":"3412"
        },
        {
          "name":"宿州市",
          "level":"3",
          "code":"3413"
        },
        {
          "name":"巢湖市",
          "level":"3",
          "code":"3414"
        },
        {
          "name":"六安市",
          "level":"3",
          "code":"3415"
        },
        {
          "name":"亳州市",
          "level":"3",
          "code":"3416"
        },
        {
          "name":"池州市",
          "level":"3",
          "code":"3417"
        },
        {
          "name":"宣城市",
          "level":"3",
          "code":"3418"
        }
      ]
    },
    {
      "name":"福建省",
      "level":"1",
      "code":"3500",
      "cities":[
        {
          "name":"福州市",
          "level":"2",
          "code":"3501"
        },
        {
          "name":"厦门市",
          "level":"3",
          "code":"3502"
        },
        {
          "name":"莆田市",
          "level":"3",
          "code":"3503"
        },
        {
          "name":"三明市",
          "level":"3",
          "code":"3504"
        },
        {
          "name":"泉州市",
          "level":"3",
          "code":"3505"
        },
        {
          "name":"漳州市",
          "level":"3",
          "code":"3506"
        },
        {
          "name":"南平市",
          "level":"3",
          "code":"3507"
        },
        {
          "name":"龙岩市",
          "level":"3",
          "code":"3508"
        },
        {
          "name":"宁德市",
          "level":"3",
          "code":"3509"
        }
      ]
    },
    {
      "name":"江西省",
      "level":"1",
      "code":"3600",
      "cities":[
        {
          "name":"南昌市",
          "level":"2",
          "code":"3601"
        },
        {
          "name":"景德镇市",
          "level":"3",
          "code":"3602"
        },
        {
          "name":"萍乡市",
          "level":"3",
          "code":"3603"
        },
        {
          "name":"九江市",
          "level":"3",
          "code":"3604"
        },
        {
          "name":"新余市",
          "level":"3",
          "code":"3605"
        },
        {
          "name":"鹰潭市",
          "level":"3",
          "code":"3606"
        },
        {
          "name":"赣州市",
          "level":"3",
          "code":"3607"
        },
        {
          "name":"吉安市",
          "level":"3",
          "code":"3608"
        },
        {
          "name":"宜春市",
          "level":"3",
          "code":"3609"
        },
        {
          "name":"抚州市",
          "level":"3",
          "code":"3610"
        },
        {
          "name":"上饶市",
          "level":"3",
          "code":"3611"
        }
      ]
    },
    {
      "name":"山东省",
      "level":"1",
      "code":"3700",
      "cities":[
        {
          "name":"济南市",
          "level":"2",
          "code":"3701"
        },
        {
          "name":"青岛市",
          "level":"3",
          "code":"3702"
        },
        {
          "name":"淄博市",
          "level":"3",
          "code":"3703"
        },
        {
          "name":"枣庄市",
          "level":"3",
          "code":"3704"
        },
        {
          "name":"东营市",
          "level":"3",
          "code":"3705"
        },
        {
          "name":"烟台市",
          "level":"3",
          "code":"3706"
        },
        {
          "name":"潍坊市",
          "level":"3",
          "code":"3707"
        },
        {
          "name":"济宁市",
          "level":"3",
          "code":"3708"
        },
        {
          "name":"泰安市",
          "level":"3",
          "code":"3709"
        },
        {
          "name":"威海市",
          "level":"3",
          "code":"3710"
        },
        {
          "name":"日照市",
          "level":"3",
          "code":"3711"
        },
        {
          "name":"莱芜市",
          "level":"3",
          "code":"3712"
        },
        {
          "name":"临沂市",
          "level":"3",
          "code":"3713"
        },
        {
          "name":"德州市",
          "level":"3",
          "code":"3714"
        },
        {
          "name":"聊城市",
          "level":"3",
          "code":"3715"
        },
        {
          "name":"滨州市",
          "level":"3",
          "code":"3716"
        },
        {
          "name":"菏泽市",
          "level":"3",
          "code":"3717"
        }
      ]
    },
    {
      "name":"河南省",
      "level":"1",
      "code":"4100",
      "cities":[
        {
          "name":"郑州市",
          "level":"2",
          "code":"4101"
        },
        {
          "name":"开封市",
          "level":"3",
          "code":"4102"
        },
        {
          "name":"洛阳市",
          "level":"3",
          "code":"4103"
        },
        {
          "name":"平顶山市",
          "level":"3",
          "code":"4104"
        },
        {
          "name":"安阳市",
          "level":"3",
          "code":"4105"
        },
        {
          "name":"鹤壁市",
          "level":"3",
          "code":"4106"
        },
        {
          "name":"新乡市",
          "level":"3",
          "code":"4107"
        },
        {
          "name":"焦作市",
          "level":"3",
          "code":"4108"
        },
        {
          "name":"濮阳市",
          "level":"3",
          "code":"4109"
        },
        {
          "name":"许昌市",
          "level":"3",
          "code":"4110"
        },
        {
          "name":"漯河市",
          "level":"3",
          "code":"4111"
        },
        {
          "name":"三门峡市",
          "level":"3",
          "code":"4112"
        },
        {
          "name":"南阳市",
          "level":"3",
          "code":"4113"
        },
        {
          "name":"商丘市",
          "level":"3",
          "code":"4114"
        },
        {
          "name":"信阳市",
          "level":"3",
          "code":"4115"
        },
        {
          "name":"周口市",
          "level":"3",
          "code":"4116"
        },
        {
          "name":"驻马店市",
          "level":"3",
          "code":"4117"
        }
      ]
    },
    {
      "name":"湖北省",
      "level":"1",
      "code":"4200",
      "cities":[
        {
          "name":"武汉市",
          "level":"2",
          "code":"4201"
        },
        {
          "name":"黄石市",
          "level":"3",
          "code":"4202"
        },
        {
          "name":"十堰市",
          "level":"3",
          "code":"4203"
        },
        {
          "name":"宜昌市",
          "level":"3",
          "code":"4205"
        },
        {
          "name":"襄樊市",
          "level":"3",
          "code":"4206"
        },
        {
          "name":"鄂州市",
          "level":"3",
          "code":"4207"
        },
        {
          "name":"荆门市",
          "level":"3",
          "code":"4208"
        },
        {
          "name":"孝感市",
          "level":"3",
          "code":"4209"
        },
        {
          "name":"荆州市",
          "level":"3",
          "code":"4210"
        },
        {
          "name":"黄冈市",
          "level":"3",
          "code":"4211"
        },
        {
          "name":"咸宁市",
          "level":"3",
          "code":"4212"
        },
        {
          "name":"随州市",
          "level":"3",
          "code":"4213"
        },
        {
          "name":"恩施土家族苗族自治州",
          "level":"3",
          "code":"4228"
        }
      ]
    },
    {
      "name":"湖南省",
      "level":"1",
      "code":"4300",
      "cities":[
        {
          "name":"长沙市",
          "level":"2",
          "code":"4301"
        },
        {
          "name":"株洲市",
          "level":"3",
          "code":"4302"
        },
        {
          "name":"湘潭市",
          "level":"3",
          "code":"4303"
        },
        {
          "name":"衡阳市",
          "level":"3",
          "code":"4304"
        },
        {
          "name":"邵阳市",
          "level":"3",
          "code":"4305"
        },
        {
          "name":"岳阳市",
          "level":"3",
          "code":"4306"
        },
        {
          "name":"常德市",
          "level":"3",
          "code":"4307"
        },
        {
          "name":"张家界市",
          "level":"3",
          "code":"4308"
        },
        {
          "name":"益阳市",
          "level":"3",
          "code":"4309"
        },
        {
          "name":"郴州市",
          "level":"3",
          "code":"4310"
        },
        {
          "name":"永州市",
          "level":"3",
          "code":"4311"
        },
        {
          "name":"怀化市",
          "level":"3",
          "code":"4312"
        },
        {
          "name":"娄底市",
          "level":"3",
          "code":"4313"
        },
        {
          "name":"湘西土家族苗族自治州",
          "level":"3",
          "code":"4331"
        }
      ]
    },
    {
      "name":"广东省",
      "level":"1",
      "code":"4400",
      "cities":[
        {
          "name":"广州市",
          "level":"2",
          "code":"4401"
        },
        {
          "name":"韶关市",
          "level":"3",
          "code":"4402"
        },
        {
          "name":"深圳市",
          "level":"3",
          "code":"4403"
        },
        {
          "name":"珠海市",
          "level":"3",
          "code":"4404"
        },
        {
          "name":"汕头市",
          "level":"3",
          "code":"4405"
        },
        {
          "name":"佛山市",
          "level":"3",
          "code":"4406"
        },
        {
          "name":"江门市",
          "level":"3",
          "code":"4407"
        },
        {
          "name":"湛江市",
          "level":"3",
          "code":"4408"
        },
        {
          "name":"茂名市",
          "level":"3",
          "code":"4409"
        },
        {
          "name":"肇庆市",
          "level":"3",
          "code":"4412"
        },
        {
          "name":"惠州市",
          "level":"3",
          "code":"4413"
        },
        {
          "name":"梅州市",
          "level":"3",
          "code":"4414"
        },
        {
          "name":"汕尾市",
          "level":"3",
          "code":"4415"
        },
        {
          "name":"河源市",
          "level":"3",
          "code":"4416"
        },
        {
          "name":"阳江市",
          "level":"3",
          "code":"4417"
        },
        {
          "name":"清远市",
          "level":"3",
          "code":"4418"
        },
        {
          "name":"东莞市",
          "level":"3",
          "code":"4419"
        },
        {
          "name":"中山市",
          "level":"3",
          "code":"4420"
        },
        {
          "name":"潮州市",
          "level":"3",
          "code":"4451"
        },
        {
          "name":"揭阳市",
          "level":"3",
          "code":"4452"
        },
        {
          "name":"云浮市",
          "level":"3",
          "code":"4453"
        }
      ]
    },
    {
      "name":"广西壮族自治区",
      "level":"1",
      "code":"4500",
      "cities":[
        {
          "name":"南宁市",
          "level":"2",
          "code":"4501"
        },
        {
          "name":"柳州市",
          "level":"3",
          "code":"4502"
        },
        {
          "name":"桂林市",
          "level":"3",
          "code":"4503"
        },
        {
          "name":"梧州市",
          "level":"3",
          "code":"4504"
        },
        {
          "name":"北海市",
          "level":"3",
          "code":"4505"
        },
        {
          "name":"防城港市",
          "level":"3",
          "code":"4506"
        },
        {
          "name":"钦州市",
          "level":"3",
          "code":"4507"
        },
        {
          "name":"贵港市",
          "level":"3",
          "code":"4508"
        },
        {
          "name":"玉林市",
          "level":"3",
          "code":"4509"
        },
        {
          "name":"百色市",
          "level":"3",
          "code":"4510"
        },
        {
          "name":"贺州市",
          "level":"3",
          "code":"4511"
        },
        {
          "name":"河池市",
          "level":"3",
          "code":"4512"
        },
        {
          "name":"来宾市",
          "level":"3",
          "code":"4513"
        },
        {
          "name":"崇左市",
          "level":"3",
          "code":"4514"
        }
      ]
    },
    {
      "name":"海南省",
      "level":"1",
      "code":"4600",
      "cities":[
        {
          "name":"海口市",
          "level":"2",
          "code":"4601"
        },
        {
          "name":"三亚市",
          "level":"3",
          "code":"4602"
        }
      ]
    },
    {
      "name":"重庆市",
      "level":"1",
      "code":"5000",
      "cities":[
        {
          "name":"重庆市",
          "level":"1",
          "code":"5000"
        }
      ]
    },
    {
      "name":"四川省",
      "level":"1",
      "code":"5100",
      "cities":[
        {
          "name":"成都市",
          "level":"2",
          "code":"5101"
        },
        {
          "name":"自贡市",
          "level":"3",
          "code":"5103"
        },
        {
          "name":"攀枝花市",
          "level":"3",
          "code":"5104"
        },
        {
          "name":"泸州市",
          "level":"3",
          "code":"5105"
        },
        {
          "name":"德阳市",
          "level":"3",
          "code":"5106"
        },
        {
          "name":"绵阳市",
          "level":"3",
          "code":"5107"
        },
        {
          "name":"广元市",
          "level":"3",
          "code":"5108"
        },
        {
          "name":"遂宁市",
          "level":"3",
          "code":"5109"
        },
        {
          "name":"内江市",
          "level":"3",
          "code":"5110"
        },
        {
          "name":"乐山市",
          "level":"3",
          "code":"5111"
        },
        {
          "name":"南充市",
          "level":"3",
          "code":"5113"
        },
        {
          "name":"眉山市",
          "level":"3",
          "code":"5114"
        },
        {
          "name":"宜宾市",
          "level":"3",
          "code":"5115"
        },
        {
          "name":"广安市",
          "level":"3",
          "code":"5116"
        },
        {
          "name":"达州市",
          "level":"3",
          "code":"5117"
        },
        {
          "name":"雅安市",
          "level":"3",
          "code":"5118"
        },
        {
          "name":"巴中市",
          "level":"3",
          "code":"5119"
        },
        {
          "name":"资阳市",
          "level":"3",
          "code":"5120"
        },
        {
          "name":"阿坝藏族羌族自治州",
          "level":"3",
          "code":"5132"
        },
        {
          "name":"甘孜藏族自治州",
          "level":"3",
          "code":"5133"
        },
        {
          "name":"凉山彝族自治州",
          "level":"3",
          "code":"5134"
        }
      ]
    },
    {
      "name":"贵州省",
      "level":"1",
      "code":"5200",
      "cities":[
        {
          "name":"贵阳市",
          "level":"2",
          "code":"5201"
        },
        {
          "name":"六盘水市",
          "level":"3",
          "code":"5202"
        },
        {
          "name":"遵义市",
          "level":"3",
          "code":"5203"
        },
        {
          "name":"安顺市",
          "level":"3",
          "code":"5204"
        },
        {
          "name":"铜仁地区",
          "level":"3",
          "code":"5222"
        },
        {
          "name":"黔西南布依族苗族自治州",
          "level":"3",
          "code":"5223"
        },
        {
          "name":"毕节地区",
          "level":"3",
          "code":"5224"
        },
        {
          "name":"黔东南苗族侗族自治州",
          "level":"3",
          "code":"5226"
        },
        {
          "name":"黔南布依族苗族自治州",
          "level":"3",
          "code":"5227"
        }
      ]
    },
    {
      "name":"云南省",
      "level":"1",
      "code":"5300",
      "cities":[
        {
          "name":"昆明市",
          "level":"2",
          "code":"5301"
        },
        {
          "name":"曲靖市",
          "level":"3",
          "code":"5303"
        },
        {
          "name":"玉溪市",
          "level":"3",
          "code":"5304"
        },
        {
          "name":"保山市",
          "level":"3",
          "code":"5305"
        },
        {
          "name":"昭通市",
          "level":"3",
          "code":"5306"
        },
        {
          "name":"丽江市",
          "level":"3",
          "code":"5307"
        },
        {
          "name":"普洱市",
          "level":"3",
          "code":"5308"
        },
        {
          "name":"临沧市",
          "level":"3",
          "code":"5309"
        },
        {
          "name":"楚雄彝族自治州",
          "level":"3",
          "code":"5323"
        },
        {
          "name":"红河哈尼族彝族自治州",
          "level":"3",
          "code":"5325"
        },
        {
          "name":"文山壮族苗族自治州",
          "level":"3",
          "code":"5326"
        },
        {
          "name":"西双版纳傣族自治州",
          "level":"3",
          "code":"5328"
        },
        {
          "name":"大理白族自治州",
          "level":"3",
          "code":"5329"
        },
        {
          "name":"德宏傣族景颇族自治州",
          "level":"3",
          "code":"5331"
        },
        {
          "name":"怒江傈僳族自治州",
          "level":"3",
          "code":"5333"
        },
        {
          "name":"迪庆藏族自治州",
          "level":"3",
          "code":"5334"
        }
      ]
    },
    {
      "name":"西藏自治区",
      "level":"1",
      "code":"5400",
      "cities":[
        {
          "name":"拉萨市",
          "level":"2",
          "code":"5401"
        },
        {
          "name":"昌都地区",
          "level":"3",
          "code":"5421"
        },
        {
          "name":"山南地区",
          "level":"3",
          "code":"5422"
        },
        {
          "name":"日喀则地区",
          "level":"3",
          "code":"5423"
        },
        {
          "name":"那曲地区",
          "level":"3",
          "code":"5424"
        },
        {
          "name":"阿里地区",
          "level":"3",
          "code":"5425"
        },
        {
          "name":"林芝地区",
          "level":"3",
          "code":"5426"
        }
      ]
    },
    {
      "name":"陕西省",
      "level":"1",
      "code":"6100",
      "cities":[
        {
          "name":"西安市",
          "level":"2",
          "code":"6101"
        },
        {
          "name":"铜川市",
          "level":"3",
          "code":"6102"
        },
        {
          "name":"宝鸡市",
          "level":"3",
          "code":"6103"
        },
        {
          "name":"咸阳市",
          "level":"3",
          "code":"6104"
        },
        {
          "name":"渭南市",
          "level":"3",
          "code":"6105"
        },
        {
          "name":"延安市",
          "level":"3",
          "code":"6106"
        },
        {
          "name":"汉中市",
          "level":"3",
          "code":"6107"
        },
        {
          "name":"榆林市",
          "level":"3",
          "code":"6108"
        },
        {
          "name":"安康市",
          "level":"3",
          "code":"6109"
        },
        {
          "name":"商洛市",
          "level":"3",
          "code":"6110"
        }
      ]
    },
    {
      "name":"甘肃省",
      "level":"1",
      "code":"6200",
      "cities":[
        {
          "name":"兰州市",
          "level":"2",
          "code":"6201"
        },
        {
          "name":"嘉峪关市",
          "level":"3",
          "code":"6202"
        },
        {
          "name":"金昌市",
          "level":"3",
          "code":"6203"
        },
        {
          "name":"白银市",
          "level":"3",
          "code":"6204"
        },
        {
          "name":"天水市",
          "level":"3",
          "code":"6205"
        },
        {
          "name":"武威市",
          "level":"3",
          "code":"6206"
        },
        {
          "name":"张掖市",
          "level":"3",
          "code":"6207"
        },
        {
          "name":"平凉市",
          "level":"3",
          "code":"6208"
        },
        {
          "name":"酒泉市",
          "level":"3",
          "code":"6209"
        },
        {
          "name":"庆阳市",
          "level":"3",
          "code":"6210"
        },
        {
          "name":"定西市",
          "level":"3",
          "code":"6211"
        },
        {
          "name":"陇南市",
          "level":"3",
          "code":"6212"
        },
        {
          "name":"临夏回族自治州",
          "level":"3",
          "code":"6229"
        },
        {
          "name":"甘南藏族自治州",
          "level":"3",
          "code":"6230"
        }
      ]
    },
    {
      "name":"青海省",
      "level":"1",
      "code":"6300",
      "cities":[
        {
          "name":"西宁市",
          "level":"2",
          "code":"6301"
        },
        {
          "name":"海东地区",
          "level":"3",
          "code":"6321"
        },
        {
          "name":"海北藏族自治州",
          "level":"3",
          "code":"6322"
        },
        {
          "name":"黄南藏族自治州",
          "level":"3",
          "code":"6323"
        },
        {
          "name":"海南藏族自治州",
          "level":"3",
          "code":"6325"
        },
        {
          "name":"果洛藏族自治州",
          "level":"3",
          "code":"6326"
        },
        {
          "name":"玉树藏族自治州",
          "level":"3",
          "code":"6327"
        },
        {
          "name":"海西蒙古族藏族自治州",
          "level":"3",
          "code":"6328"
        }
      ]
    },
    {
      "name":"宁夏回族自治区",
      "level":"1",
      "code":"6400",
      "cities":[
        {
          "name":"银川市",
          "level":"2",
          "code":"6401"
        },
        {
          "name":"石嘴山市",
          "level":"3",
          "code":"6402"
        },
        {
          "name":"吴忠市",
          "level":"3",
          "code":"6403"
        },
        {
          "name":"固原市",
          "level":"3",
          "code":"6404"
        },
        {
          "name":"中卫市",
          "level":"3",
          "code":"6405"
        }
      ]
    },
    {
      "name":"新疆维吾尔自治区",
      "level":"1",
      "code":"6500",
      "cities":[
        {
          "name":"乌鲁木齐市",
          "level":"2",
          "code":"6501"
        },
        {
          "name":"克拉玛依市",
          "level":"3",
          "code":"6502"
        },
        {
          "name":"吐鲁番地区",
          "level":"3",
          "code":"6521"
        },
        {
          "name":"哈密地区",
          "level":"3",
          "code":"6522"
        },
        {
          "name":"昌吉回族自治州",
          "level":"3",
          "code":"6523"
        },
        {
          "name":"博尔塔拉蒙古自治州",
          "level":"3",
          "code":"6527"
        },
        {
          "name":"巴音郭楞蒙古自治州",
          "level":"3",
          "code":"6528"
        },
        {
          "name":"阿克苏地区",
          "level":"3",
          "code":"6529"
        },
        {
          "name":"克孜勒苏柯尔克孜自治州",
          "level":"3",
          "code":"6530"
        },
        {
          "name":"喀什地区",
          "level":"3",
          "code":"6531"
        },
        {
          "name":"和田地区",
          "level":"3",
          "code":"6532"
        },
        {
          "name":"伊犁哈萨克自治州",
          "level":"3",
          "code":"6540"
        },
        {
          "name":"塔城地区",
          "level":"3",
          "code":"6542"
        },
        {
          "name":"阿勒泰地区",
          "level":"3",
          "code":"6543"
        }
      ]
    },
    {
      "name":"台湾省",
      "level":"1",
      "code":"7100",
      "cities":[
        {
          "name":"台湾省",
          "level":"1",
          "code":"7100"
        }
      ]
    },
    {
      "name":"香港特别行政区",
      "level":"1",
      "code":"8100",
      "cities":[
        {
          "name":"香港特别行政区",
          "level":"1",
          "code":"8100"
        }
      ]
    },
    {
      "name":"澳门特别行政区",
      "level":"1",
      "code":"8200",
      "cities":[
        {
          "name":"澳门特别行政区",
          "level":"1",
          "code":"8200"
        }
      ]
    }
  ]
